import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { PrimaryButton } from '../../components/Button';
import Link from '../../components/Link';

const Governance: React.FC = () => {
    const theme: any = useTheme();

    return (
        <Box sx={{
            width: '100%',
            maxHeight: '100vh',
            boxSizing: 'border-box',
            paddingLeft: '38px',
            position: 'relative',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                padding: '70px 20px',
                height: 'auto'
            }
        }}>
            <Stack alignItems='center' sx={{
                width: '100%',
                marginTop: '75px',
                height: '100%',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '0px'
                }
            }}>
                <Stack justifyContent='space-evenly' alignItems='center' sx={{
                    textAlign: 'center',
                    maxWidth: '600px',
                    margin: '0px auto'
                }}>
                    <h2>
                        Governance + Lament
                    </h2>
                    <Box component='p' sx={{
                        margin: '0px 0px 20px 0px'
                    }}>
                        Lament is experimenting with the concept of governance in the context of a brand by 
                        implementing a hybrid system of governance. Auctioned clothing inherently holds voting power within 
                        the brand treasury.
                    </Box>
                </Stack>
                <Box component='img' src='/assets/lament-logo-traditional.svg' sx={{
                    height: '70vh',
                    margin: '30px 0px 30px 0px',
                    zIndex: 1,
                    [theme.breakpoints.down('sm')]: {
                        height: 'auto',
                        width: '80vw'
                    },
                    [theme.breakpoints.up('xl')]: {
                        height: '60vh'
                    }
                }} />
            </Stack>
        </Box>
    );
}

export default Governance;
