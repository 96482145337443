import { Box } from '@mui/material';
import React from 'react';

interface Props {
    underline?: boolean;
    href?: string;
    target?: string;
    children?: React.ReactNode;
}

const Link: React.FC<Props> = (props) => {
    return (
        <Box component='a' href={props.href} target={props.target} sx={{
            color: 'black',
            '&:hover': {
                textDecoration: props.underline ? 'underline' : 'none'
            }
        }}>
            { props.children }
        </Box>
    );
}

export default Link;
