import React from 'react';
import Sidebar from '../../components/Sidebar';
import {
  Box
} from '@mui/material';
import {
  useTheme
} from '@mui/styles'
import Footer from '../../components/Footer';
import MobileNav from '../../components/MobileNav';
import Nav from '../../components/Nav';
import Governance from './Governance';
import Hero from './Hero';
import About from './About';

const App: React.FC = () => {
  const theme = useTheme();

  return (
    <>
      <Sidebar />
      <Nav hideLogo={true} />
      <MobileNav />
      <Hero />
      <About />
      <Governance />
      <Footer />
    </>
  );
}

export default App;
