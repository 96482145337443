import React from 'react';
import {
  Box, keyframes
} from '@mui/material';
import { useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';

const textScroll = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(-100%);
  }
`;

const textScroll2 = keyframes`
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-200%);
  }
`;

const Sidebar: React.FC = () => {
  let theme: any = useTheme();

  return (
    <Box sx={{
      width: '38px', 
      height: '100vh',
      position: 'fixed',
      left: 0,
      top: 0,
      background: 'black',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '12px 7px',
      boxSizing: 'border-box',
      zIndex: '100',
      [theme.breakpoints.down('sm')]: {
          visibility: 'hidden'
      }
    }}>
        <Link to='/'>
            <img src="/assets/logo-white.svg" width="30px"/>
        </Link>
        <Box sx={{
            marginTop: '10px',
            overflow: 'hidden',
            height: '100%',
        }}>
            <Box sx={{
                animation: `${textScroll} 15s linear infinite`,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                position: 'relative',
                left: '0px',
                height: '100%',
                'p': {
                    color: 'white',
                    writingMode: 'vertical-rl',
                    fontWeight: '300',
                    fontSize: '16px'
                }
            }}>
                <p>
                    THE CAR IS ON FIRE AND THERE'S NO DRIVER ON THE WHEEL
                </p>
            </Box>
            <Box sx={{
                animation: `${textScroll2} 15s linear infinite`,
                animationDelay: '7.5s',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                position: 'relative',
                height: '100%',
                left: '0px',
                'p': {
                    color: 'white',
                    writingMode: 'vertical-rl',
                    fontWeight: '300',
                    fontSize: '16px'
                },
                '@media(max-height: 545px)': {
                    visibility: 'hidden'
                }
            }}>
                <p>
                    THE CAR IS ON FIRE AND THERE'S NO DRIVER ON THE WHEEL
                </p>
            </Box>
        </Box>
    </Box>
  );
}

export default Sidebar;
