import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { PrimaryButton } from '../../components/Button';
import Link from '../../components/Link';

const About: React.FC = () => {
    const theme: any = useTheme();

    return (
        <Box sx={{
            width: '100%',
            maxHeight: '100vh',
            boxSizing: 'border-box',
            paddingLeft: '38px',
            position: 'relative',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                padding: '70px 20px',
                height: 'auto'
            }
        }}>
            <Stack alignItems='center' sx={{
                width: '100%',
                marginTop: '100px',
                height: '100%',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '70px'
                }
            }}>
                <Stack justifyContent='space-evenly' alignItems='center' sx={{
                    textAlign: 'center',
                    maxWidth: '600px',
                    margin: '0px auto'
                }}>
                    <h2>
                        Lament Clothing
                    </h2>
                    <Box component='p' sx={{
                        margin: '0px 0px 20px 0px'
                    }}>
                        Lament is a fully on-chain fashion label. Clothing is regularly auctioned, limited and with fixed supply 
                        in the form of NFT's. These may be redeemed on-chain for the physical works, where the NFT will remain in 
                        existence, transitioning to a generative artwork while retaining token utilities.
                    </Box>
                </Stack>
                <Stack direction='row' justifyContent='center' alignItems='flex-start' sx={{
                    minWidth: '100%',
                    paddingTop: '25px'
                }}>
                    <Box component='img' src='/assets/about-1.jpg' sx={{
                        height: '55vh',
                        background: '#F0EFEF',
                        zIndex: 1,
                        margin: '0px 5px',
                        [theme.breakpoints.down('sm')]: {
                            height: '35vh'
                        }
                    }} />
                    <Box component='img' src='/assets/about-2.jpg' sx={{
                        height: '55vh',
                        background: '#F0EFEF',
                        zIndex: 1,
                        margin: '0px 5px',
                        [theme.breakpoints.down('sm')]: {
                            height: '35vh'
                        }
                    }} />
                    <Box component='img' src='/assets/about-3.jpg' sx={{
                        height: '55vh',
                        background: '#F0EFEF',
                        zIndex: 1,
                        margin: '0px 5px',
                        [theme.breakpoints.down('sm')]: {
                            height: '35vh'
                        }
                    }} />
                    <Box component='img' src='/assets/about-4.jpg' sx={{
                        height: '55vh',
                        background: '#F0EFEF',
                        zIndex: 1,
                        margin: '0px 5px',
                        [theme.breakpoints.down('sm')]: {
                            height: '35vh'
                        }
                    }} />
                    <Box component='img' src='/assets/about-5.jpg' sx={{
                        height: '55vh',
                        background: '#F0EFEF',
                        zIndex: 1,
                        margin: '0px 5px',
                        [theme.breakpoints.down('sm')]: {
                            height: '35vh'
                        }
                    }} />
                </Stack>
            </Stack>
        </Box>
    );
}

export default About;
