import { Box, Stack } from '@mui/material';
import React from 'react';

const Footer: React.FC = () => {
    return (
        <Box sx={{
            height: '82px',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTop: '1px solid #000000',
            'a': {
                color: 'black',
                textDecoration: 'none',
                '&:hover': {
                    color: 'black',
                    textDecoration: 'underline'
                }
            },
            '*': {
                fontWeight: '300',
                fontSize: '14px'
            }
        }}>
            <Stack direction='row' spacing='10px'>
                <span>
                    © 2021 Lament
                </span>
                <a href='https://twitter.com/lament_co' target='_blank'>
                    Twitter
                </a>
                <a href='https://instagram.com/lament.co' target='_blank'>
                    Instagram
                </a>
                <a href='https://discord.gg/G9kXBYreqA' target='_blank'>
                    Discord
                </a>
            </Stack>
        </Box>
    );
};

export default Footer;