import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
        fontFamily: 'paralucent'
      },
      '*, *::before, *::after': {
        boxSizing: 'border-box'
      },
      body: {
        height: '100%',
        width: '100%',
        margin: '0px'
      },
      '#root': {
        height: '100%',
        width: '100%'
      },
      'a': {
          color: 'black',
          textDecoration: 'none',
          '&:hover': {
              textDecoration: 'underline',
              cursor: 'pointer'
          }
      },
      'h2': {
        fontWeight: '400',
        fontSize: '18px',
        letterSpacing: '1.6px',
        textTransform: 'uppercase',
        margin: '15px'
      },
      'p': {
        fontWeight: '300',
        fontSize: '14px',
        letterSpacing: '1.4px'
      }
    }
  })
);

const GlobalStyles: React.FC = () => {
  useStyles();
  return null;
};

export default GlobalStyles;
