import { Divider, Drawer, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useTheme } from '@mui/styles';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Link as ReactLink } from 'react-router-dom';
import Link from '../Link';

const MobileNav: React.FC = () => {
    const theme: any = useTheme();
    const [open, setOpen] = useState(false);
    
    const toggleDrawer = (open: boolean) => {
        return () => {
            setOpen(open);
        }
    }

    return (
        <>
            <Box sx={{
                position: 'fixed',
                width: '100vw',
                height: '62px',
                left: 0,
                top: 0,
                background: '#FFFFFF',
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0px 15px',
                alignItems: 'center',
                boxSizing: 'border-box',
                zIndex: 100,
                borderBottom: '1px solid #000000',
                [theme.breakpoints.up('sm')]: {
                    visibility: 'hidden'
                }
            }}>
                <ReactLink to='/'>
                    <img src='/assets/logo.svg' height='37px' />
                </ReactLink>
                <Box onClick={toggleDrawer(true)}>
                    <img src='/assets/nav.svg' width='37px' />
                </Box>
            </Box>
            <Drawer
                anchor='left'
                open={open}
                onClose={toggleDrawer(false)}
            >
                <List sx={{
                    width: '60vw'
                }}>
                    <Link 
                        href='https://painted-raja-68e.notion.site/Lament-A-Decentralized-Brand-47a073bda4b74dfbbe03aea606976612' 
                        target='_blank'
                        underline={false}
                    >
                        <ListItemButton>
                            <ListItemText primary='Documentation' />
                        </ListItemButton>
                    </Link>
                </List>
            </Drawer>
        </>
    );
}

export default MobileNav;
