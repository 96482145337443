import { Box, Button } from '@mui/material';
import React from 'react';
import { LinkArrow } from '../Icons';

interface Props {
    children?: React.ReactNode;
}

const PrimaryButton: React.FC<Props> = (props) => {
    return (
        <Button sx={{
            height: '44px',
            borderRadius: '27px',
            border: '1px solid #000000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px 20px',
            background: 'white',
            color: 'black',
            fontWeight: '300',
            fontSize: '14px',
            boxSizing: 'border-box',
            '&:hover': {
                background: '#FDF504'
            },
        }}>
            { props.children }
        </Button>
    );
}

const PaperButton: React.FC<Props> = (props) => {
    return (
        <Box sx={{
            '#button-shadow': {
                background: '#FDF504'
            },
            '&:hover': {
                '#button-shadow': {
                    background: 'black'
                },
                cursor: 'pointer'
            },
            marginRight: '-3px',
            fontSize: '18px',
            fontWeight: '300',
            minWidth: '120px',
            height: '48px',
            transition: 'background 1s linear'
        }}>
            <Box sx={{
                minWidth: '120px',
                height: '44px',
                borderRadius: '27px',
                border: '2px solid #000000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                zIndex: 2,
                padding: '0px 20px',
                background: 'white',
                boxSizing: 'border-box'
            }}>
                <Box sx={{ width: '100%' }}>
                    { props.children }
                </Box>
                <Box sx={{width: '15px', height: '15px', marginLeft: '15px'}}>
                    <LinkArrow />
                </Box>
            </Box>
            <Box id="button-shadow" sx={{
                width: '100%',
                height: '44px',
                borderRadius: '27px',
                border: '2px solid #000000',
                zIndex: 1,
                position: 'relative',
                top: '-40px',
                right: '3px',
                boxSizing: 'border-box'
            }}/>
        </Box>
    );
}

export { PaperButton, PrimaryButton };
