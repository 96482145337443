import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { PrimaryButton } from '../../components/Button';
import Countdown from '../../components/Countdown';
import Link from '../../components/Link';

const Hero: React.FC = () => {
    const theme: any = useTheme();

    return (
        <Box sx={{
            width: '100%',
            height: '100vh',
            boxSizing: 'border-box',
            paddingLeft: '38px',
            position: 'relative',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                padding: '0px 20px 20px 20px'
            }
        }}>
            <Stack alignItems='center' justifyContent='space-between' sx={{
                width: '100%',
                height: '100%',
                margin: '0px 0px 50px 0px',
                [theme.breakpoints.down('sm')]: {
                    marginTop: '50px'
                }
            }}>
                <Box component='img' src='/assets/hero.jpg' sx={{
                    height: '70vh',
                    paddingTop: '40px',
                    zIndex: 1,
                    flexGrow: 1,
                    [theme.breakpoints.down('sm')]: {
                        height: '55vh',
                        paddingTop: '0px',
                    }
                }} />
                <Stack justifyContent='center' alignItems='center' sx={{
                    textAlign: 'center',
                    maxWidth: '600px',
                    margin: '0px auto',
                    flexGrow: 2,
                    zIndex: 2
                }}>
                    <Box component='p' sx={{
                        margin: '0px 0px 20px 0px'
                    }}>
                        Lament is an experimental, ethereum-native, decentralized brand. The label, physical works, 
                        and treasury are all controlled and maintained by the community; where each work grants partial control over 
                        the brand and it's treasury.
                    </Box>
                    <Link href='https://painted-raja-68e.notion.site/Lament-A-Decentralized-Brand-47a073bda4b74dfbbe03aea606976612' target='_blank'>
                        <PrimaryButton>
                            Documentation
                        </PrimaryButton>
                    </Link>
                </Stack>
            </Stack>
        </Box>
    );
}

export default Hero;
