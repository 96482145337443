import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import Link from '../Link';

interface Props {
    hideLogo?: boolean;
}

const Nav: React.FC<Props> = (props) => {
    const theme: any = useTheme();

    return (
        <Box sx={{
            position: 'fixed',
            width: props.hideLogo ? 'calc(100% - 38px)' : '100%',
            height: '52px',
            left: 0,
            top: 0,
            background: '#FFFFFF',
            padding: '0px 20px',
            borderBottom: '1px solid #000000',
            boxSizing: 'border-box',
            marginLeft: props.hideLogo ? '38px' : '0px',
            zIndex: 3,
            [theme.breakpoints.down('sm')]: {
                visibility: 'hidden'
            },
            '*': {
                fontWeight: '300',
                fontSize: '16xpx'
            }
        }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{
                width: '100%',
                height: '100%'
            }}>
                <Stack direction='row' spacing='15px'>
                    {
                        props.hideLogo ?
                        <></> :
                        <ReactLink to='/'>
                            <img src='/assets/lament-logo.svg' height='37px' />
                        </ReactLink>
                    }
                    <Link 
                        href='https://painted-raja-68e.notion.site/Lament-A-Decentralized-Brand-47a073bda4b74dfbbe03aea606976612' 
                        target='_blank'
                        underline={true}
                    >
                        <span>
                            Learn
                        </span>
                    </Link>
                </Stack>
                <Box component='img' src='/assets/wordmark.svg' sx={{
                    'width': '100px',
                    '&:hover': {
                        cursor: 'pointer',
                    }
                }} />
            </Stack>
        </Box>
    );
};

export default Nav;
